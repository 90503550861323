import '../../estilo/estilo.css'

function Flex(){
    return(
        <div>
            <div className='flex-container'>

                <div className='flex-1'></div>

                <div className='flex-2'></div>

                <div className='flex-3'></div>

            </div>







        </div>
    )
}

export default Flex;